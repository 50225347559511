export const VISITOR_PERMITS_REQUEST = "VISITOR_PERMITS_REQUEST";
export const VISITOR_PERMITS_SUCCESS = "VISITOR_PERMITS_SUCCESS";
export const VISITOR_PERMITS_FAILURE = "VISITOR_PERMITS_FAILURE";

export const DELETE_VISITOR_PERMIT_REQUEST = "DELETE_VISITOR_PERMIT_REQUEST";
export const DELETE_VISITOR_PERMIT_SUCCESS = "DELETE_VISITOR_PERMIT_SUCCESS";
export const DELETE_VISITOR_PERMIT_FAILURE = "DELETE_VISITOR_PERMIT_FAILURE";

export const ADD_VISITOR_PERMIT_REQUEST = "ADD_VISITOR_PERMIT_REQUEST";
export const ADD_VISITOR_PERMIT_SUCCESS = "ADD_VISITOR_PERMIT_SUCCESS";
export const ADD_VISITOR_PERMIT_FAILURE = "ADD_VISITOR_PERMIT_FAILURE";

export const DETAILS_VISITOR_PERMIT_REQUEST = "DETAILS_VISITOR_PERMIT_REQUEST";
export const DETAILS_VISITOR_PERMIT_SUCCESS = "DETAILS_VISITOR_PERMIT_SUCCESS";
export const DETAILS_VISITOR_PERMIT_FAILURE = "DETAILS_VISITOR_PERMIT_FAILURE";
export const DETAILS_VISITOR_PERMIT_RESET = "DETAILS_VISITOR_PERMIT_RESET";

export const UPDATE_VISITOR_PERMIT_REQUEST = "UPDATE_VISITOR_PERMIT_REQUEST";
export const UPDATE_VISITOR_PERMIT_SUCCESS = "UPDATE_VISITOR_PERMIT_SUCCESS";
export const UPDATE_VISITOR_PERMIT_FAILURE = "UPDATE_VISITOR_PERMIT_FAILURE";
export const UPDATE_VISITOR_PERMIT_RESET = "UPDATE_VISITOR_PERMIT_RESET";

export const UPDATE_PERMIT_APPROVAL_STATUS_REQUEST =
	"UPDATE_PERMIT_APPROVAL_STATUS_REQUEST";
export const UPDATE_PERMIT_APPROVAL_STATUS_SUCCESS =
	"UPDATE_PERMIT_APPROVAL_STATUS_SUCCESS";
export const UPDATE_PERMIT_APPROVAL_STATUS_FAILURE =
	"UPDATE_PERMIT_APPROVAL_STATUS_FAILURE";

export const VISITOR_PERMITS_USERS_REQUEST = "VISITOR_PERMITS_USERS_REQUEST";
export const VISITOR_PERMITS_USERS_SUCCESS = "VISITOR_PERMITS_USERS_SUCCESS";
export const VISITOR_PERMITS_USERS_FAILURE = "VISITOR_PERMITS_USERS_FAILURE";
export const VISITOR_PERMITS_USERS_RESET = "VISITOR_PERMITS_USERS_RESET";

export const DELETE_VISITOR_PERMIT_USERS_REQUEST =
	"DELETE_VISITOR_PERMIT_USERS_REQUEST";
export const DELETE_VISITOR_PERMIT_USERS_SUCCESS =
	"DELETE_VISITOR_PERMIT_USERS_SUCCESS";
export const DELETE_VISITOR_PERMIT_USERS_FAILURE =
	"DELETE_VISITOR_PERMIT_USERS_FAILURE";

export const ADD_VISITOR_PERMIT_USERS_REQUEST =
	"ADD_VISITOR_PERMIT_USERS_REQUEST";
export const ADD_VISITOR_PERMIT_USERS_SUCCESS =
	"ADD_VISITOR_PERMIT_USERS_SUCCESS";
export const ADD_VISITOR_PERMIT_USERS_FAILURE =
	"ADD_VISITOR_PERMIT_USERS_FAILURE";

export const VISITOR_PERMITS_ALL_USERS_REQUEST =
	"VISITOR_PERMITS_ALL_USERS_REQUEST";
export const VISITOR_PERMITS_ALL_USERS_SUCCESS =
	"VISITOR_PERMITS_ALL_USERS_SUCCESS";
export const VISITOR_PERMITS_ALL_USERS_FAILURE =
	"VISITOR_PERMITS_ALL_USERS_FAILURE";

export const SEND_PERMITS_REQUEST = "SEND_PERMITS_REQUEST";
export const SEND_PERMITS_SUCCESS = "SEND_PERMITS_SUCCESS";
export const SEND_PERMITS_FAILURE = "SEND_PERMITS_FAILURE";

export const SEND_SELF_LINK_REQUEST = "SEND_SELF_LINK_REQUEST";
export const SEND_SELF_LINK_SUCCESS = "SEND_SELF_LINK_SUCCESS";
export const SEND_SELF_LINK_FAILURE = "SEND_SELF_LINK_FAILURE";

export const CHECK_VISITOR_REQUEST = "CHECK_VISITOR_REQUEST";
export const CHECK_VISITOR_SUCCESS = "CHECK_VISITOR_SUCCESS";
export const CHECK_VISITOR_FAILURE = "CHECK_VISITOR_FAILURE";
